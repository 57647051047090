.fixtures-container {
    max-width: 290px;
    padding-left: 20px;
}

.selected-svg {
    outline: green solid 2px;
}

.unselected-svg {
    outline: none;
}

.standardFixture {
    color: rgb(94, 28, 202);
    font-style: italic;
    font-size: smaller;
}