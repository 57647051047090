.App {
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  text-align: left;
  height: 100%;
}

.App-header {
  background-color: #AA4444;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 50px;
}

.App-link {
  color: #61dafb;
}