// Contains both the svg map container, and the rack info side bar
.smart-map {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.svg-container {
    height: auto;
    overflow: hidden;
    flex-direction: column;
    display: inline-flex;
    flex-grow: 1;
}

/* Tooltip text */
.tooltip {
    visibility: visible;
    width: auto;//120px;
    background-color: black;
    color: #fff;
    text-align: left;
    padding: 5px 5px;
    border-radius: 6px;

    position: absolute;
    z-index: 9999999;
    pointer-events: none;
    float: left;
}
.snap { font: italic 13px serif; fill: rgb(0, 0, 0); }