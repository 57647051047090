.svg-text {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    pointer-events: none;
    font-family: "Helvetica";
    vertical-align: middle;
    alignment-baseline: middle;
    dominant-baseline: middle;
    text-anchor: middle;
}

.debug-label-group-box {
    fill: rgba(0, 0, 0, 0.25);
}