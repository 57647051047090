.modal {
    //position: fixed;
    top: 0px;
    right: 0%;
    bottom: 0px;
    transform: translate(0%, 0%);
    background-color: white;
    border: 0;
    border-width: 0;
    height: 100%;
    overflow: hidden;
}

.modal-iframe {
    border: 0;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
    //pointer-events: none;
}

.close {
    width: 100%;
    background-color: #AAAAAA;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.modal-content {
    overflow-y: auto;
    max-height: 90vh;
}